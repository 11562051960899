import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Text, Block } from 'components/atoms'
import { Alert } from 'components/molecules'

import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'

import { displayError } from 'helpers/functions'

// ICONS
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const styles = theme => ({
  form: {
    width: '100%', // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
})

class SignUpForm extends React.Component {
  static defaultProps = {}

  static propTypes = {}

  constructor() {
    super()
    this.emailInput = React.createRef()
    this.passwordInput = React.createRef()
    this.confirmPasswordInput = React.createRef()
    this.state = {
      loading: false,
      errorCode: null,
      email: null,
      password: null,
      confirmPassword: null,
      showPassword: null,
      showConfirmPassword: null,
    }
    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleSubmit = e => {
    e.preventDefault()
    const { onSubmit } = this.props
    const { confirmPassword, password, email } = this.state
    if (password !== confirmPassword) {
      return this.setState({ errorCode: 'password-mismatch' })
    }
    this.setState({ loading: true })
    return onSubmit(email, password)
      .then(() => this.setState({ loading: false }))
      .catch(error => {
        this.setState({
          errorCode: error && error.error ? error.error.code : error.code,
          loading: false,
        })
      })
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value })
  }

  render() {
    const { classes, onBack } = this.props
    const {
      loading,
      errorCode,
      email,
      password,
      confirmPassword,
      showConfirmPassword,
      showPassword,
    } = this.state
    return (
      <Block.Middle flexDirection={'column'}>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <FormControl margin="dense" required fullWidth>
            <InputLabel>Email</InputLabel>
            <Input
              type={'text'}
              value={email}
              placeholder={'example@gmail.com'}
              onChange={this.handleChange('email')}
            />
          </FormControl>
          <FormControl margin="dense" required fullWidth>
            <InputLabel htmlFor="adornment-newPassword">Password</InputLabel>
            <Input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={this.handleChange('password')}
              inputProps={{ minLength: 6 }}
              placeholder={'โปรดกรอก 6 ตัวอักษรขึ้นไป'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.setState({ showPassword: !showPassword })
                    }
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl margin="dense" required fullWidth>
            <InputLabel htmlFor="adornment-newPassword">
              Confirm Password
            </InputLabel>
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={this.handleChange('confirmPassword')}
              inputProps={{ minLength: 6 }}
              placeholder={'โปรดกรอก 6 ตัวอักษรขึ้นไป'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        showConfirmPassword: !showConfirmPassword,
                      })
                    }
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button.Contain type="submit" fullWidth mt={30} loading={loading}>
            สมัครสมาชิก
          </Button.Contain>
        </form>
        <Alert
          open={!!errorCode}
          status={'danger'}
          title={displayError(errorCode)}
          onClose={() => this.setState({ errorCode: null })}
        />
        <Text link onClick={() => onBack()} mt={30}>
          {'< เข้าสู่ระบบ'}
        </Text>
      </Block.Middle>
    )
  }
}

const StyledSignUpForm = withStyles(styles)(SignUpForm)

StyledSignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
}

export default StyledSignUpForm
