import React from 'react'
import { navigate } from 'gatsby'
import { compose } from 'recompose'

import {
  Block,
  Text,
  Button,
  GatsbyImageBackground,
  GatsbyImage,
  Loader,
} from 'components/atoms'
import { Header, Alert } from 'components/molecules'
import { LoginLineModal, TransactionModal } from 'components/organisms'
import firebase from 'appFirebase/config'
import CloudApi from 'api/cloudApi'
import EmailSignIn from 'components/forms/EmailSignIn'
import EmailSignUp from 'components/forms/EmailSignUp'
import withTheme from 'hocs/withTheme'
import { withResizeDetector } from 'react-resize-detector'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'

// REDUX
import { getProfile } from 'modules/auth/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//HELPERS
import { LineURL, genLineURL } from 'helpers/constants'
import { displayError } from 'helpers/functions'
import qs from 'qs'

// Lodash
import get from 'lodash/get'
import pick from 'lodash/pick'

// Theme
import { withTheme as withEmotion } from 'emotion-theming'

// Image
const LineIcon = 'icon/line.png'
const FacebookIcon = 'icon/fb.png'
const DesktopBackGround = 'CRM-1440x326.jpg'
const MobileBackGround = 'CRM-828x450.jpg'

const facebookProvider = new firebase.auth.FacebookAuthProvider()
// facebookProvider.addScope('user_birthday')
facebookProvider.addScope('public_profile')
facebookProvider.addScope('email')
// facebookProvider.addScope('user_gender')

class UserLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formState: 'login',
      showLoadingWhenLoginLine: false,
      showLoadingWhenLoginFacebook: false,
      showLoginLineModal: false,
      showTransactionModal: false,
      showLoadingGoToNextPage: false,
      error: null,
      event: null,
      loadingText: '',
    }
  }

  componentDidMount() {
    const { location, intl } = this.props
    const formState = get(location, 'state.formState')
    if (formState) this.setState({ formState })

    this.setState({
      showLoadingWhenLoginFacebook: true,
      showLoadingWhenLoginLine: true,
    })
    firebase
      .auth()
      .getRedirectResult()
      .then(data => {
        if (data.user && firebase.auth().currentUser) {
          this.setState({
            loadingText: intl.formatMessage({ id: 'loadingTextFacebook' }),
          })
          const providerData = data.user.providerData[0]
          const dataToSend = {
            ...pick(providerData, ['displayName', 'email', 'photoURL']),
            uid: data.user.uid,
          }
          return CloudApi.loginFacebook(dataToSend).then(() =>
            this.checkEventCode()
          )
        } else this.setState({ showLoadingWhenLoginFacebook: false })
      })
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    // Line login
    if (query.id_token && query.access_token) {
      this.setState({
        showLoadingWhenLoginLine: true,
        loadingText: intl.formatMessage({ id: 'loadingTextLine' }),
      })
      return CloudApi.loginWithLine(
        { id_token: query.id_token },
        query.access_token
      )
        .then(({ token }) =>
          firebase
            .auth()
            .signInWithCustomToken(token)
            .then(() => this.checkEventCode())
        )
        .catch(err =>
          this.setState({ error: err.message, showLoadingWhenLoginLine: false })
        )
    } else {
      this.setState({ showLoadingWhenLoginLine: false })
    }

    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     return navigate('/')
    //   }
    // })
  }

  checkEventCode = () => {
    const { location, intl } = this.props
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.eventCode) {
      this.setState({
        showLoadingGoToNextPage: true,
        showLoadingWhenLoginLine: false,
        showLoadingWhenLoginFacebook: false,
        loadingText: intl.formatMessage({ id: 'goingToMainPage' }),
      })
      return CloudApi.joinEvent(query.eventCode).then(event => {
        if (event) {
          this.setState({
            event,
            showTransactionModal: true,
            showLoadingGoToNextPage: false,
          })
        } else {
          navigate('/')
        }
      })
    }
    return navigate('/')
  }

  getLineUrl = () => {
    const { location } = this.props
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.eventCode) {
      return genLineURL({ eventCode: query.eventCode })
    } else {
      return LineURL
    }
  }

  renderForm = () => {
    const { formState } = this.state
    if (formState === 'signup') {
      return (
        <Block pl={[20, 0]} pr={[20, 0]}>
          <EmailSignUp
            onSubmit={this.onSignUp}
            onExit={() => this.setState({ openSignUpForm: false })}
            onBack={() => this.setState({ formState: 'login' })}
          />
        </Block>
      )
    }
    return (
      <Block pl={[20, 0]} pr={[20, 0]}>
        <Block>
          <EmailSignIn
            onSubmit={(email, password) => {
              return firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(() => this.checkEventCode())
            }}
          />
        </Block>
        <Block
          borderTop={'1px solid #b4b4b4'}
          mt={45}
          position={'relative'}
          css={{
            '.MuiTypography-body1': {
              transform: 'translate(-50%, 0)',
            },
          }}
        >
          <Text
            position={'absolute'}
            top={-24}
            background={'white'}
            p={'10px 15px 10px 15px'}
            borderRadius={'50%'}
            left={'49%'}
            transform={'translate(-50%, 0)'}
            color={'lightGrey'}
            fontWeight={'bold'}
          >
            <FormattedMessage id={'or'} />
          </Text>
        </Block>
        <Button.Contain
          className={'facebook'}
          fullWidth
          startIcon={<GatsbyImage src={FacebookIcon} width={20} />}
          mt={30}
          onClick={() => {
            firebase.auth().signInWithRedirect(facebookProvider)
          }}
        >
          <FormattedMessage id={'loginByFacebook'} />
        </Button.Contain>
        <Button.Contain
          className={'line'}
          fullWidth
          mt={20}
          startIcon={<GatsbyImage src={LineIcon} width={20} />}
          onClick={() => {
            console.log(this.getLineUrl())
            window.location = `${this.getLineUrl()}`
          }}
        >
          <FormattedMessage id={'loginByLine'} />
        </Button.Contain>
        <Block textAlign={'center'}>
          <Text
            link
            mt={30}
            onClick={() => this.setState({ formState: 'signup' })}
          >
            <FormattedMessage id={'createNewAccount'} />
          </Text>
        </Block>
      </Block>
    )
  }

  onSignUp = (email, password) => {
    return CloudApi.register({
      email,
      password,
      displayName: email,
      photoURL: `https://ui-avatars.com/api/?background=5d5d5d&color=ee5b36&name=${email}`,
    })
      .then(() =>
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => this.checkEventCode())
      )
      .catch(err => {
        const errorCode = get(err, 'error.code')
        if (errorCode === 'auth/email-already-use-in-line-login') {
          this.setState({
            showLoginLineModal: true,
          })
        } else {
          this.setState({
            error: errorCode,
          })
        }
      })
  }

  showLoading = () => {
    const {
      showLoadingWhenLoginFacebook,
      showLoadingWhenLoginLine,
      showLoadingGoToNextPage,
      loadingText,
    } = this.state
    if (
      showLoadingWhenLoginFacebook ||
      showLoadingWhenLoginLine ||
      showLoadingGoToNextPage
    ) {
      return (
        <Block.MiddleCenter flex={1}>
          <Loader text={loadingText} />
        </Block.MiddleCenter>
      )
    }
    return null
  }

  render() {
    const {
      error,
      showLoginLineModal,
      formState,
      showTransactionModal,
      event,
    } = this.state
    const { width, intl } = this.props

    return (
      <Block>
        <Header />
        <GatsbyImageBackground
          src={width < 640 ? MobileBackGround : DesktopBackGround}
          backgroundStyle={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Block
            backgroundSize={'cover'}
            width={'100%'}
            height={['25vh', '42.5vh']}
            mb={0}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {/* <Text variant={'h1'} color={'white'}>
              {formState === 'login' ?
                'เข้าสู่ระบบ'
                :
                'สมัครสมาชิก'
              }
            </Text> */}
          </Block>
        </GatsbyImageBackground>
        <Block
          position={'relative'}
          display={'flex'}
          flexDirection={'column'}
          backgroundSize={'cover'}
          justifyContent={'center'}
          alignItems={'center'}
          backgroundPosition={'center'}
          py={[15, 10]}
        >
          <Block mt={[10, 10]} width={['100%', '50%']}>
            {this.renderForm()}
          </Block>
        </Block>
        {this.showLoading()}
        <Alert
          open={error !== null}
          status={'danger'}
          title={
            displayError(error) || intl.formatMessage({ id: 'somethingWrong' })
          }
          onClose={() => this.setState({ error: null })}
        />
        <LoginLineModal
          open={showLoginLineModal}
          onClose={() => this.setState({ showLoginLineModal: false })}
          getLineUrl={this.getLineUrl}
        />
        {event && (
          <TransactionModal
            open={showTransactionModal}
            onClose={() => {
              this.setState({ showTransactionModal: false })
              navigate('/')
            }}
            event={event}
          />
        )}
      </Block>
    )
  }
}

export default compose(
  connect(
    null,
    dispatch =>
      bindActionCreators(
        {
          getProfile,
        },
        dispatch
      )
  ),
  withTheme(),
  withEmotion,
  withResizeDetector
)(injectIntl(UserLogin))
